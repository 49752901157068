import React, {Component,Fragment} from 'react';
import {Container, Nav, Navbar} from "react-bootstrap";


import {NavLink} from "react-router-dom";
class TopNavigation extends Component {

    render() {
        return (
            <Fragment  fluid="true">
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Container>
                        <Navbar.Brand href="#/home">Directory</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link > <NavLink to="/home">Home</NavLink> </Nav.Link>
                                <Nav.Link ><NavLink to="/customer">New Client</NavLink></Nav.Link>
                                <Nav.Link ><NavLink to="/order">New Info</NavLink></Nav.Link>
                                <Nav.Link ><NavLink to="/report">Report</NavLink></Nav.Link>
                                <Nav.Link ><NavLink to="/bin">BIN</NavLink></Nav.Link>
                                <Nav.Link ><NavLink to="/unit">Unit Profile</NavLink></Nav.Link>
                                <Nav.Link ><NavLink to="/hscode">Hscode</NavLink></Nav.Link>
                                <Nav.Link ><NavLink to="/">Logout</NavLink></Nav.Link>
                            </Nav>

                        </Navbar.Collapse>
                    </Container>
                </Navbar>

            </Fragment>
        );
    }
}
export default TopNavigation;