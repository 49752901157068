import React, {Component, Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import CustomerEntry from "../components/Customer/CustomerEntry";



class CustomerEntryPage extends Component {




    render() {
        return (
            <Fragment>
              <TopNavigation/>
                <CustomerEntry district={this.props.district} upazila={this.props.upazila} union={this.props.union}  bazar={this.props.bazar}/>


            </Fragment>
        );
    }
}

export default CustomerEntryPage;