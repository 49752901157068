import React, {Component} from 'react';
import Login from "../components/Login/Login";

class LoginPage extends Component {
    render() {
        return (
            <div>
                <Login/>
                
            </div>
        );
    }
}

export default LoginPage;