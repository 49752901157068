import React, {Component, Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";


class HomePage extends Component {
    render() {
        return (
            <Fragment className="">

                <TopNavigation/>



            </Fragment>
        );
    }
}

export default HomePage;