import cogoToast from 'cogo-toast';
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import React, {Component, Fragment} from 'react';
import {Button, Col, Container, Nav, Row, Table} from "react-bootstrap";
import {NavLink} from "react-router-dom";
class CustomerEntry extends Component {

    constructor() {
        super();

        this.state={


            Districts:[],
            Upazilas:[],
            Unions:[],
            Bazars:[],

            new_upazila:[],
            new_union:[],
            new_bazar:[],
            loading:true

        }
    }




    componentDidMount() {

        this.setState({Districts:this.props.district});
        this.setState({Upazilas:this.props.upazila});
        this.setState({Unions:this.props.union});
        this.setState({Bazars:this.props.bazar});



    }

    getUpazila=()=>{


        const e = document.getElementById("ddlDistrict");
        const value = e.options[e.selectedIndex].value;
        const dt=this.state.Upazilas.filter(x => x.district_id==value);
        this.setState({new_upazila: dt});
        this.setState({new_union: []});
        this.setState({new_bazar: []});
    }
    getUnion=()=>{
        const e = document.getElementById("ddlUpazila");
        const value = e.options[e.selectedIndex].value;
        const dt=this.state.Unions.filter(x => x.upazila_id==value);
        this.setState({new_union: dt});
        this.setState({new_bazar:[]});


    }




    saveNewCustomer=()=>{
        let district = document.getElementById("ddlDistrict");
        let upazila = document.getElementById("ddlUpazila");
        let union = document.getElementById("ddlUnion");
        let profession = document.getElementById("ddlProfession");
        let category = document.getElementById("ddlCategory");

        let districtName=district.options[district.selectedIndex].text;
        let upazilaName=upazila.options[upazila.selectedIndex].text;
        let unionName=union.options[union.selectedIndex].text;
        let professionName=profession.options[profession.selectedIndex].text;
        let categoryName=category.options[category.selectedIndex].text;

        let village = document.getElementById("ddlVillage").value;
        let postOffice = document.getElementById("ddlPostOffice").value;
        let name = document.getElementById("ddlName").value;
        let f_name = document.getElementById("ddlFName").value;
        let m_Name = document.getElementById("ddlMName").value;
        let nid = document.getElementById("ddlNid").value;
        let mobile = document.getElementById("ddlMobile").value;
        let status = document.getElementById("ddlStatus").value;






        let jsonObject=
            {
                District:districtName,
                Upazila:upazilaName,
                Ward_Union:unionName,
                Village:village,
                Post_Office:postOffice,
                Name:name,
                F_Name:f_name,
                M_Name:m_Name,
                NID:nid,
                Mobile:mobile,
                Profession:professionName,
                Category:categoryName,
                Status:status

            };
        RestClient.PostRequest(AppUrl.saveClient,jsonObject).then(result=>{

                                cogoToast.success("Client Saved Successfully")

                                    }).catch(error=>{
                                        alert("Wrong")
                                    });

    }

    render() {
        return (

            <Fragment>
                <div className="purchaseDiv text-center">
                    <Nav.Link className="navItem "><NavLink to="/home"><Button variant="warning" className="float-end">Close</Button></NavLink></Nav.Link>

                    <h3>New Client  Entry Form</h3>
                    <hr/>

                    <Container>
                        <Row>
                            <Col lg={3} md={3} sm={12}>
                                <Table>
                                    <tr>
                                        <td>
                                            <select className="float-start w-100 customfontsize" id="ddlDistrict"  onChange={this.getUpazila} >
                                                <option value="0">All District</option>

                                                {


                                                    this.state.Districts.map((ctr,index)=>{
                                                        return (
                                                            <option key={index} value={ctr.id}>{ctr.name}</option>
                                                        )
                                                    })

                                                }


                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <select className="float-start w-100 customfontsize" id="ddlUpazila" onChange={this.getUnion}>
                                                <option value="0">All Upazila</option>

                                                {
                                                    this.state.new_upazila.map((ctr,index)=>{
                                                        return (
                                                            <option key={index} value={ctr.id}>{ctr.name}</option>
                                                        )
                                                    })
                                                }


                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <select className="float-start w-100 customfontsize" id="ddlUnion"  >
                                                <option>All Union</option>

                                                {

                                                    this.state.new_union.map((ctr,index)=>{
                                                        return (
                                                            <option key={index} value={ctr.id}>{ctr.name}</option>
                                                        )
                                                    })

                                                }


                                            </select>
                                        </td>
                                    </tr>


                                </Table>

                            </Col>
                            <Col lg={3}>

                                <Row>
                                    <label className="customfontsize">Profession</label>
                                    <select className="justify-content-center float-start w-100 customfontsize" id="ddlProfession"  >
                                        <option>Business</option>
                                        <option>Gov. Service</option>
                                        <option>Private Service</option>
                                    </select>
                                </Row>
                                <Row>
                                    <label className="customfontsize">Sub-Category</label>
                                    <select className="float-start w-100 customfontsize" id="ddlCategory"  >
                                        <option>-Select Category-</option>

                                        {

                                            this.state.Bazars.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.id}>{ctr.name}</option>
                                                )
                                            })

                                        }


                                    </select>
                                </Row>
                                <Row>
                                    <label className="customfontsize">Name</label>
                                    <input id="ddlName" type="text" className="w-100 customfontsize"/>
                                </Row>
                                <Row>
                                    <label className="customfontsize">Father Name</label>
                                    <input id="ddlFName" type="text" className="w-100  customfontsize"/>
                                </Row>
                                <Row>
                                    <label className="customfontsize">Mother Name</label>
                                    <input id="ddlMName" type="text" className="w-100  customfontsize"/>
                                </Row>
                                <Row>
                                    <label className="customfontsize">NID</label>
                                    <input id="ddlNid" type="text" className="w-100  customfontsize"/>
                                </Row>
                                <Row>
                                    <label className="customfontsize">Mobile</label>
                                    <input id="ddlMobile" type="text" className="w-100  customfontsize"/>
                                </Row>
                                <Row>
                                    <label className="customfontsize">Village Name</label>
                                    <input id="ddlVillage" type="text" className="w-100  customfontsize"/>
                                </Row>
                                <Row>
                                    <label className="customfontsize">Post Office</label>
                                    <input id="ddlPostOffice" type="text" className="w-100  customfontsize"/>
                                </Row>
                                <Row>
                                    <label className="customfontsize">Status</label>
                                    <input id="ddlStatus" type="text" className="w-100  customfontsize"/>
                                </Row>

                                <Row >
                                    <Col lg={6} md={6} sm={12}><Button onClick={this.saveNewCustomer} variant="success" className="w-100 mt-4">Save</Button></Col>
                                     </Row>

                            </Col>


                        </Row>

                    </Container>

                </div>

            </Fragment>
        );
    }
}

export default CustomerEntry;