import React, {Component, Fragment} from 'react';
import {Button, Col, Container, Nav, Row, Table} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import error from "../../asset/image/error.svg";
import loading from "../../asset/image/loader.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cogoToast from "cogo-toast";

class OrderReport extends Component {

    constructor(props) {
        super();

        this.state={
            startDate:null,
            Order:[],
            Columns:[ {dataField: 'id', text: 'Order No', sort: true}],
            show:false,
            DivVisibility:"hidden",
            beema:0,
            beemaPlus:0,
            farco:0,
            farcoPlus:0,

            Districts:[],
            Upazilas:[],
            Unions:[],
            Bazars:[],

            new_upazila:[],
            new_union:[],
            new_bazar:[],

        }
    }

    componentDidMount() {
        if(this.props.district==null){
            this.setState({Loading:true})
        }else{

            this.setState({Loading:false})
            this.setState({Districts:this.props.district,loading:false})
            this.setState({Upazilas:this.props.upazila,loading:false})
            this.setState({Unions:this.props.union,loading:false})
            this.setState({Bazars:this.props.bazar,loading:false})

        }




    }
    getUpazila=()=>{


        const e = document.getElementById("ddlDistrict");
        const value = e.options[e.selectedIndex].value;
        const dt=this.state.Upazilas.filter(x => x.district_id==value);
        this.setState({new_upazila: dt});
        this.setState({new_union:[]});
        this.setState({new_bazar:[]});
    }
    getUnion=()=>{
        const e = document.getElementById("ddlUpazila");
        const value = e.options[e.selectedIndex].value;
        const dt=this.state.Unions.filter(x => x.upazila_id==value);
        this.setState({new_union: dt});
        this.setState({new_bazar:[]});

    }
    getBazar=()=>{
        const e = document.getElementById("ddlUnion");
        const value = e.options[e.selectedIndex].value;
        const dt=this.state.Bazars.filter(x => x.union_id==value);
        this.setState({new_bazar:dt});


    }

    divShow=()=>{
        document.getElementById("ddlDiv").style.visibility = "visible";


    }
    divHidden=()=>{
        document.getElementById("ddlDiv").style.visibility = "hidden";

    }

    getOrderBySql=()=>{

        this.getDate();
        let Order_Date=document.getElementById("htag").innerText

        let Type = document.getElementById("ddlType");
        let typeIndex=Type.selectedIndex;

        let sr = document.getElementById("ddlSr");
        let srName=sr.options[sr.selectedIndex].text;

        let bzr = document.getElementById("ddlBazar");
        let bzrName=bzr.options[bzr.selectedIndex].text;
        if(this.state.startDate==null){alert("Please Enter Orer Date")}else{
            if(typeIndex==0){

                let SR="";
                let orderDate="";
                let BazarSql="";

                if(this.state.startDate!=null){orderDate="and Order_Date='"+Order_Date+"'";}
                if(sr.selectedIndex !=0){SR=" and Sr_Name='"+srName+"'";}
                if(bzr.selectedIndex !=0){BazarSql=" and Bazar_Name='"+bzrName+"'";}

                let jsonObject=
                    {
                        sql:orderDate + SR +BazarSql,


                    };

                RestClient.PostRequest(AppUrl.getOrderSummery,jsonObject).then(result=>{
                    this.getSummeryColumns();
                    if(result==null){

                        document.getElementById("NoData").style.visibility="visible";
                    }else{
                        this.setState({Order:result, error:false});


                        document.getElementById("WentWrong").style.visibility="hidden";
                        document.getElementById("ddlDiv").style.visibility="visible";


                    }

                }).catch(error=>{
                    document.getElementById("NoInternet").style.visibility="visible";


                });

            }else{


                let SR="";
                let orderDate="";
                let BazarSql="";

                if(this.state.startDate!=null){orderDate="and Order_Date='"+Order_Date+"'";}
                if(sr.selectedIndex !=0){SR=" and Sr_Name='"+srName+"'";}
                if(bzr.selectedIndex !=0){BazarSql=" and Bazar_Name='"+bzrName+"'";}

                let jsonObject=
                    {
                        sql:orderDate + SR +BazarSql,


                    };

                RestClient.PostRequest(AppUrl.getOrderBySql,jsonObject).then(result=>{
                    this.getDetailesColumns();
                    if(result==null){

                        document.getElementById("NoData").style.visibility="visible";
                    }else{
                        this.setState({Order:result, error:false});


                        document.getElementById("WentWrong").style.visibility="hidden";
                        document.getElementById("ddlDiv").style.visibility="visible";


                    }

                }).catch(error=>{
                    document.getElementById("NoInternet").style.visibility="visible";


                });
            }
        }


    }
    getDate=()=>{
        let d = new Date(this.state.startDate),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;


        let sazzad=[year, month, day].join('-');

        document.getElementById("htag").innerText=sazzad;

    }

    getDetailesColumns=()=>{
       let DetailesColumns=[
            {dataField: 'id', text: 'Order No', sort: true},
            {dataField: 'Order_Date', text: 'Order_Date', sort: true},
            {dataField: 'Customer_Name', text: 'Customer', sort: true},
            {dataField: 'Bazar_Name', text: 'Bazar', sort: true},
            {dataField: 'Sr_Name', text: 'SR', sort: true},
            {dataField: 'Beema', text: 'Beema', sort: true},
            {dataField: 'BeemaPlus', text: 'Beema+', sort: true},
            {dataField: 'Farco', text: 'Farco', sort: true},
            {dataField: 'FarcoPlus', text: 'Farco+', sort: true},
        ]
        this.setState({Columns:DetailesColumns})
    }
    getSummeryColumns=()=>{
        let SummeryColumns=[
            {dataField: 'Sr_Name', text: 'Sr_Name', sort: true},
            {dataField: 'Beema', text: 'Beema Ctn', sort: true},
            {dataField: 'Beema_Price', text: 'Beema_Price', sort: true},

            {dataField: 'BeemaPlus', text: 'BeemaPlus Ctn', sort: true},
            {dataField: 'BeemaPlus_Price', text: 'Beema+ Price', sort: true},

            {dataField: 'Farco', text: 'Farco Qty', sort: true},
            {dataField: 'Farco_Price', text: 'Farco Price', sort: true},

            {dataField: 'FarcoPlus', text: 'FarcoPlus Ctn', sort: true},
            {dataField: 'FarcoPlus_Price', text: 'Farco+ Price', sort: true},


        ]
        this.setState({Columns:SummeryColumns})


    }


    render() {



            return (

                <Fragment>
                    <Container >
                        <div className="purchaseDiv text-center">
                            <Nav.Link className="navItem "><NavLink to="/home"><Button variant="warning" className="float-end">Close</Button></NavLink></Nav.Link>

                            <h3>Order Report Form</h3>

                            <hr/>
                            <Container>
                                <Row>
                                    <Col lg={3} md={3} sm={12}>
                                        <Table>
                                            <tr>
                                                <td>
                                                    <select className="float-start w-100 customfontsize" id="ddlDistrict"  onChange={this.getUpazila} >
                                                        <option value="0">-All District-</option>

                                                        {


                                                            this.state.Districts.map((ctr,index)=>{
                                                                return (
                                                                    <option key={index} value={ctr.id}>{ctr.name}</option>
                                                                )
                                                            })

                                                        }


                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <select className="float-start w-100 customfontsize" id="ddlUpazila" onChange={this.getUnion}>
                                                        <option value="0">-All Upazila-</option>

                                                        {
                                                            this.state.new_upazila.map((ctr,index)=>{
                                                                return (
                                                                    <option key={index} value={ctr.id}>{ctr.name}</option>
                                                                )
                                                            })
                                                        }


                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <select className="float-start w-100 customfontsize" id="ddlUnion"  onChange={this.getBazar} >
                                                        <option>-All Union-</option>

                                                        {

                                                            this.state.new_union.map((ctr,index)=>{
                                                                return (
                                                                    <option key={index} value={ctr.id}>{ctr.name}</option>
                                                                )
                                                            })

                                                        }


                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <select className="float-start w-100 customfontsize" id="ddlBazar"  >
                                                        <option>-All Bazar-</option>

                                                        {

                                                            this.state.new_bazar.map((ctr,index)=>{
                                                                return (
                                                                    <option key={index} value={ctr.id}>{ctr.name}</option>
                                                                )
                                                            })

                                                        }


                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <select className="float-start w-100 customfontsize" id="ddlType"  >
                                                        <option>Summery Report</option>
                                                        <option>Detailes Report</option>


                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <select className="float-start w-100 customfontsize" id="ddlSr"  >
                                                        <option>-All SR-</option>
                                                        <option>sahin</option>
                                                        <option>mehedi</option>
                                                        <option>eusuf</option>

                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label className="">Selected Date :</label><label className="text-danger" id="htag"></label>


                                                    <DatePicker selected={this.state.startDate} onChange={date=>this.setState({startDate:date})}dateFormat='dd/MM/yyyy'
                                                                seletable
                                                                maxDate={new Date()}
                                                                isClearable
                                                                showYearDropdown
                                                                scrollableMonthYearDropdown
                                                    />


                                                </td>
                                            </tr>
                                        </Table>

                                    </Col>
                                    <Col lg={3}>


                                        <Row >
                                             <Col lg={6} md={6} sm={12}><Button onClick={this.getOrderBySql} variant="success" className="w-100 mt-4">Search</Button></Col>

                                        </Row>

                                    </Col>


                                </Row>

                            </Container>



                            <h1 id="NoData" className="VisibilityHidden">No Data Found</h1>
                            <img id="NoInternet" className="LoaderAnimation VisibilityHidden " src={error}/>
                            <img id="WentWrong" className="LoaderAnimation VisibilityHidden " src={loading}/>

                        </div>


                        <div id="ddlDiv" className="overflow-scroll VisibilityHidden">
                            <div className="tableDiv">
                                <BootstrapTable  keyField='id' data={this.state.Order} columns={this.state.Columns} > </BootstrapTable>
                            </div>

                        </div>

                    </Container>

                </Fragment>

            );
        }


}

export default OrderReport;