import React, {Component, Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import BinReport from "../components/BIN/BinReport";

class BinPage extends Component {
    render() {
        return (
            <Fragment>
                <TopNavigation/>
                    <BinReport/>
            </Fragment>
        );
    }
}

export default BinPage;