import React, {Component, Fragment} from 'react';
import {Button, Col, Container, Nav, Row, Tab, Table, Tabs} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import {NavLink} from "react-router-dom";

class ClientReport extends Component {

    constructor() {
        super();
        this.state={
            key:"home",
            Customer_Id:"",
            Customer:[],

             District:"",
             Upazila:"",
             Ward_Union:"",
             Village:"",
             Profession:"",
             NID:"",
             Mobile:"",
            General_Info:"Please Select the name from Table, To See Information",


        }
    }


    getContactDetailes=(k)=>{
         this.setState({key:k})
            if(k=="home"){
                let jsonObject={sql:"Select  * from Client where  Id ="+this.state.Customer_Id+" ",};

                RestClient.PostRequest(AppUrl.getClient,jsonObject).then(result=>{
                    this.setState({District:result[0]['District']})
                    this.setState({Upazila:result[0]['Upazila']})
                    this.setState({Ward_Union:result[0]['Ward_Union']})
                    this.setState({Village:result[0]['Village']})
                    this.setState({NID:result[0]['NID']})
                    this.setState({Mobile:result[0]['Mobile']})
                    this.setState({Profession:result[0]['Profession']})



                });
            }
        if(k=="private"){
            let jsonObject={sql:"Select * from Private_Info where  Client_Id ="+this.state.Customer_Id+" ",};

            RestClient.PostRequest(AppUrl.getClient,jsonObject).then(result=>{
                let info="=> ";
                result.map((ctr,index)=>{
                    info=info+".   "+ctr.Msg;


                })

                this.setState({General_Info:info});});
        }
        if(k=="business"){
            let jsonObject={sql:"Select * from Business_Info where  Client_Id ="+this.state.Customer_Id+" ",};

            RestClient.PostRequest(AppUrl.getClient,jsonObject).then(result=>{
                let info="=> ";
                result.map((ctr,index)=>{
                    info=info+".   "+ctr.Msg;


                })

                this.setState({General_Info:info});


            });
        }
        if(k=="general"){
            let jsonObject={sql:"Select Msg from General_Info where  Client_Id ="+this.state.Customer_Id+" ",};

            RestClient.PostRequest(AppUrl.getClient,jsonObject).then(result=>{
            let info="=> ";
                result.map((ctr,index)=>{
                    info=info+".   "+ctr.Msg;
                })
                this.setState({General_Info:info});
            });
        }
        if(k=="income"){
            let jsonObject={sql:"Select Income from Income_Info where  Client_Id ="+this.state.Customer_Id+" ",};

            RestClient.PostRequest(AppUrl.getClient,jsonObject).then(result=>{
                let info="=> ";
                result.map((ctr,index)=>{
                    info=info+".   "+ctr.Income;
                })
                this.setState({General_Info:info});
            });
        }


    }
    getClient=()=>{

        let category = document.getElementById("ddlCategory");
        let categoryName=category.options[category.selectedIndex].text;
        let categoryInput = document.getElementById("ddlCategoryInput").value;


            if(category.selectedIndex==0){cogoToast.error("please select Category"); category.focus()}else{

                let District="";
                let Upazila="";
                let Ward_Union="";
                let Village="";
                let Profession="";
                let NID="";
                let Mobile="";
                let Name="";

                    if(category.selectedIndex==1){District="and  District LIKE '%"+categoryInput+"%' ";}
                    if(category.selectedIndex==2){Upazila="and  Upazila LIKE '%"+categoryInput+"%'";}
                    if(category.selectedIndex==3){Ward_Union="and  Ward_Union LIKE '%"+categoryInput+"%'";}
                    if(category.selectedIndex==4){Village="and  Village LIKE '%"+categoryInput+"%'";}
                    if(category.selectedIndex==5){Mobile="and  Mobile LIKE '%"+categoryInput+"%'";}
                    if(category.selectedIndex==6){NID="and  NID LIKE '%"+categoryInput+"%'";}
                    if(category.selectedIndex==7){Profession="and  Profession LIKE '%"+categoryInput+"%'";}
                    if(category.selectedIndex==8){Name="and  Name LIKE '%"+categoryInput+"%'";}



                let jsonObject=
                    {
                        sql:"Select * from Client where 1=1 "+District+"  "+Upazila+" "+Ward_Union+" "+Village+"  "+Profession+" "+NID+" "+Mobile+" "+Name+" ",

                    };
                    if(categoryInput !=""){
                        RestClient.PostRequest(AppUrl.getClient,jsonObject).then(result=>{

                        this.setState({Customer:result,loading:false})
                    });
                    }else{cogoToast.error("Please insert data")}



            }




    }

    render() {



        const columns=[
            {dataField: 'Id', text: 'ID',sort:true},
            {dataField: 'Name', text:'Name',sort:true},
            {dataField: 'F_Name', text: 'Father name',sort:true},
            {dataField: 'Mobile', text: 'Mobile',sort:true},
            {dataField: 'District', text: 'District',sort:true},


        ]
        const selectRow={
            mode:'radio',
            onSelect:(row,isSelect,rowIndex)=>{
                this.setState({Customer_Id:row['Id']});

            }

        }




        return (
            <Fragment>

                <div className="purchaseDivSmall">
                    <Nav.Link className="navItem "><NavLink to="/home"><Button variant="warning" className="float-end">Close</Button></NavLink></Nav.Link>

                    <Container>
                    <Row>
                        <Col lg={3} md={3} sm={12}>
                            <Table>
                                <tr>
                                    <td>
                                        <select className="float-start w-100 mt-4 customfontsize" id="ddlCategory"  >
                                            <option value="0">-Select Category-</option>
                                            <option value="0">District</option>
                                            <option value="0">Upazila</option>
                                            <option value="0">Union/ward</option>
                                            <option value="0">Village</option>
                                            <option value="0">Mobile</option>
                                            <option value="0">NID</option>
                                            <option value="0">Profession</option>
                                            <option value="0">Name</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Col lg={6} md={6} sm={12}><input id="ddlCategoryInput" className="w-100 mt-4"/></Col>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Col lg={6} md={6} sm={12}><Button onClick={this.getClient} variant="success" className="w-100 mt-4">Search</Button></Col>

                                    </td>
                                </tr>
                            </Table>

                        </Col>
                    </Row>
                </Container>
                    <BootstrapTable
                        keyField='Id'
                        data={this.state.Customer }
                        columns={ columns }
                        selectRow={selectRow}
                    >
                    </BootstrapTable>
                </div>

                <Tabs
                    id="ReportTabs"
                    //activeKey={this.state.key}
                    onSelect={this.getContactDetailes}
                    className="mb-3"
                >
                    <Tab eventKey="home" title="Home">
                           <div>
                               <Table className="text-left">
                                   <tr>
                                       <td>
                                                District
                                       </td>
                                       <td>
                                           {this.state.District}
                                       </td>
                                   </tr>
                                   <tr>
                                       <td>
                                           Upazila
                                       </td>
                                       <td>
                                           {this.state.Upazila}
                                       </td>
                                   </tr>
                                   <tr>
                                       <td>
                                           Union/Ward
                                       </td>
                                       <td>
                                           {this.state.Ward_Union}
                                       </td>
                                   </tr>
                                   <tr>
                                       <td>
                                           Village:
                                       </td>
                                       <td>
                                           {this.state.Village}
                                       </td>
                                   </tr>
                                   <tr>
                                       <td>
                                           Mobile:
                                       </td>
                                       <td>
                                           {this.state.Mobile}
                                       </td>
                                   </tr>
                                   <tr>
                                       <td>
                                           NID
                                       </td>
                                       <td>
                                           {this.state.NID}
                                       </td>
                                   </tr>
                                   <tr>
                                       <td>
                                           Profession
                                       </td>
                                       <td>
                                           {this.state.Profession}
                                       </td>
                                   </tr>

                               </Table>
                           </div>
                    </Tab>
                    <Tab eventKey="business" title="Business">
                        <textarea className="min-vw-100 min-vh-100" value={this.state.General_Info}></textarea>
                    </Tab>
                    <Tab eventKey="general" title="General Info" onClick={this.getContactDetailes} >
                        <textarea className="min-vw-100 min-vh-100" value={this.state.General_Info}>


                        </textarea>
                    </Tab>
                    <Tab eventKey="private" title="Private Info" onClick={this.getContactDetailes} >
                        <textarea className="min-vw-100 min-vh-100" value={this.state.General_Info}></textarea>
                    </Tab>
                    <Tab eventKey="income" title="Income Info" onClick={this.getContactDetailes} >
                        <textarea className="min-vw-100 min-vh-100" value={this.state.General_Info}></textarea>
                    </Tab>
                </Tabs>
            </Fragment>
        );
    }
}

export default ClientReport;