import React, {Component, Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Hscode from "../components/BIN/Hscode";

class HscodePage extends Component {
    render() {
        return (
            <Fragment>
                <TopNavigation/>
                <Hscode/>
            </Fragment>
        );
    }
}

export default HscodePage;