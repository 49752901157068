import React, {Component, Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import BazarEntry from "../components/Bazar/BazarEntry";

class BazarPage extends Component {

    render() {
        return (
            <Fragment>
                <TopNavigation/>
                <BazarEntry district={this.props.district} upazila={this.props.upazila} union={this.props.union}  />

            </Fragment>
        );
    }
}

export default BazarPage;