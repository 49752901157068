import React, {Component, Fragment} from 'react';
import {Button, Col, Container, Nav, Row, Tab, Table, Tabs} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import {NavLink} from "react-router-dom";

class ClientReport extends Component {

    constructor() {
        super();
        this.state={
            key:"home",
            Customer_Id:"",
            Customer:[],



        }
    }


    getTabKey=(k)=>{

        this.setState({key:k})
    }


    getClient=()=>{

        let category = document.getElementById("ddlCategory");
        let categoryName=category.options[category.selectedIndex].text;
        let categoryInput = document.getElementById("ddlCategoryInput").value;


            if(category.selectedIndex==0){cogoToast.error("please select Category"); category.focus()}else{

                let Hscode="";
                let Description="";



                    if(category.selectedIndex==1){Hscode="and  hscode LIKE '%"+categoryInput+"%' ";}
                    if(category.selectedIndex==2){Description="and  description LIKE '%"+categoryInput+"%'";}




                let jsonObject=
                    {
                        sql:"Select * from Hscode where 1=1 "+Hscode+"  "+Description+"  Limit 500 ",


                    };
                    if(categoryInput !=""){
                        RestClient.PostRequest(AppUrl.getClient,jsonObject).then(result=>{

                        this.setState({Customer:result,loading:false})
                    });
                    }else{cogoToast.error("Please insert data")}



            }




    }

    render() {



        const columns=[
            {dataField: 'id', text: 'ID',sort:true},
            {dataField: 'hscode', text:'Hscode',sort:true},
            {dataField: 'description', text: 'Description',sort:true},


        ]
        const selectRow={
            mode:'radio',
            onSelect:(row,isSelect,rowIndex)=>{
                this.setState({Customer_Id:row['id']});

            }

        }




        return (
            <Fragment>

                <div className="purchaseDivSmall">
                    <Nav.Link className="navItem "><NavLink to="/home"><Button variant="warning" className="float-end">Close</Button></NavLink></Nav.Link>

                    <Container></Container>
                    <Row>
                        <Col lg={3} md={3} sm={12}>
                            <Table>
                                <tr>
                                    <td>
                                        <select className="float-start w-100 mt-4 customfontsize" id="ddlCategory"  >
                                            <option value="0">-Select Category-</option>
                                            <option value="0">Hscode</option>
                                            <option value="0">Description</option>


                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Col lg={6} md={6} sm={12}><input id="ddlCategoryInput" className="w-100 mt-4"/></Col>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Col lg={6} md={6} sm={12}><Button onClick={this.getClient} variant="success" className="w-100 mt-4">Search</Button></Col>

                                    </td>
                                </tr>
                            </Table>

                        </Col>
                    </Row>



                </div>
                <div className="binTbl scroll ">

                    <BootstrapTable
                        keyField='id'
                        data={this.state.Customer }
                        columns={ columns }
                        selectRow={selectRow}

                    >
                    </BootstrapTable>
                </div>

                <div>

                    <Tabs
                        id="ReportTabs"
                        //activeKey={this.state.key}
                        onSelect={this.getTabKey}
                        className="mb-3"
                    >
                        <Tab eventKey="home" title="Home">

                        </Tab>
                        <Tab eventKey="business" title="Business">

                        </Tab>
                        <Tab eventKey="general" title="General Info" onClick={this.getContactDetailes} >


                        </Tab>
                        <Tab eventKey="private" title="Private Info" onClick={this.getContactDetailes} >

                        </Tab>
                        <Tab eventKey="income" title="Income Info" onClick={this.getContactDetailes} >

                        </Tab>
                    </Tabs>
                </div>


            </Fragment>
        );
    }
}

export default ClientReport;