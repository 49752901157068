import React, {Component} from 'react';
import { HashRouter, Route, Routes} from "react-router-dom";

import HomePage from "../pages/HomePage";
import CustomerEntryPage from "../pages/CustomerEntryPage";
import BazarPage from "../pages/BazarPage";
import OrderPage from "../pages/OrderPage";

import LoginPage from "../pages/LoginPage";
import OrderReportPage from "../pages/OrderReportPage";
import RestClient from "../RestAPI/RestClient";
import AppUrl from "../RestAPI/AppUrl";
import ReportPage from "../pages/ReportPage";
import BinPage from "../pages/BinPage";
import UnitProfilePage from "../pages/UnitProfilePage";
import HscodePage from "../pages/HscodePage";




class AppRoute extends Component {
    constructor() {
        super();
        this.state={

            Districts:[],
            Upazilas:[],
            Unions:[],
            Bazars:[],

        }
    }




    componentDidMount() {
        RestClient.GetRequest(AppUrl.getDistrict).then(result=>{ this.setState({Districts:result});});
        RestClient.GetRequest(AppUrl.getUpazila).then(result=>{this.setState({Upazilas:result});});
        RestClient.GetRequest(AppUrl.getUnion).then(result=>{this.setState({Unions:result});});
        RestClient.GetRequest(AppUrl.getCategory).then(result=>{ this.setState({Bazars:result});});
    }


    render() {

        return (


            <HashRouter >

                <Routes>
                    <Route path="/"         element={<LoginPage />}></Route>
                    <Route path="/home"     element={<HomePage />}></Route>
                    <Route path="/customer" element={<CustomerEntryPage district={this.state.Districts} upazila={this.state.Upazilas} union={this.state.Unions}  bazar={this.state.Bazars} />}></Route>
                    <Route path="/bazar"   element={<BazarPage district={this.state.Districts} upazila={this.state.Upazilas} union={this.state.Unions} />}></Route>
                    <Route path="/order"   element={<OrderPage district={this.state.Districts} upazila={this.state.Upazilas} union={this.state.Unions}  bazar={this.state.Bazars} />}></Route>
                    <Route path="/report"   element={<ReportPage district={this.state.Districts} upazila={this.state.Upazilas} union={this.state.Unions}  bazar={this.state.Bazars} />}></Route>
                    <Route path="/bin"   element={<BinPage />}></Route>
                    <Route path="/unit"   element={<UnitProfilePage />}></Route>
                    <Route path="/hscode"   element={<HscodePage />}></Route>

                    <Route path="/orderReport" element={<OrderReportPage district={this.state.Districts} upazila={this.state.Upazilas} union={this.state.Unions}  bazar={this.state.Bazars} />}></Route>


                </Routes>

            </HashRouter>





        );
    }
}

export default AppRoute;