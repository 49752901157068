import React, {Component, Fragment} from 'react';
import {Button, Col, Container, Modal, Nav, Row, Table} from "react-bootstrap";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import {NavLink} from "react-router-dom";
import cogoToast from "cogo-toast";
import BootstrapTable from 'react-bootstrap-table-next';
import Loading from "../Loading/Loading";



class OrderEntry extends Component {
    constructor(props) {
        super(props);

        this.state={
            Loading:true,

            Customer:[],
            show:false,

            Districts:[],
            Upazilas:[],
            Unions:[],
            Bazars:[],

            new_upazila:[],
            new_union:[],
            new_bazar:[],

            Customer_Id:"",
            Customer_Name:"",
            Bazar_Id:"",
            rowDataID:"",
            BazarName:"",
            Customer_Mobile:"",



        }
    }


    componentDidMount() {



            if(this.props.district==null){
                this.setState({Loading:true})
            }else{

                this.setState({Loading:false})
                this.setState({Districts:this.props.district,loading:false})
                this.setState({Upazilas:this.props.upazila,loading:false})
                this.setState({Unions:this.props.union,loading:false})
                this.setState({Bazars:this.props.bazar,loading:false})
            }




    }
    getUpazila=()=>{


        const e = document.getElementById("ddlDistrict");
        const value = e.options[e.selectedIndex].value;
        const dt=this.state.Upazilas.filter(x => x.district_id==value);
        this.setState({new_upazila: dt});
        this.setState({new_union:[]});

    }
    getUnion=()=>{
        const e = document.getElementById("ddlUpazila");
        const value = e.options[e.selectedIndex].value;
        const dt=this.state.Unions.filter(x => x.upazila_id==value);
        this.setState({new_union: dt});

    }

     handleClose = () =>{

        this.setState({show:false});


    }


    handleShow = (event) => {

        this.setState({show:true});

    }

    getCustomerBy=()=>{

        let district = document.getElementById("ddlDistrict");
        let upazila = document.getElementById("ddlUpazila");
        let union = document.getElementById("ddlUnion");
        let bazar = document.getElementById("ddlBazar");



        let districtName=district.options[district.selectedIndex].text;
        let upazilaName=upazila.options[upazila.selectedIndex].text;
        let unionName=union.options[union.selectedIndex].text;
        let bazarName=bazar.options[bazar.selectedIndex].text;
        let bazarId=bazar.options[bazar.selectedIndex].value;

        let District;
        if(district.selectedIndex==0){District=""}else{District ="and District='"+districtName+"' "}

        let Upazila;
        if(upazila.selectedIndex==0){Upazila=""}else{Upazila ="and Upazila='"+upazilaName+"'     "}

        let Ward_Union;
        if(union.selectedIndex==0){Ward_Union=""}else{Ward_Union ="and Ward_Union='"+unionName+"'     "}






        this.setState({Bazar_Id:bazarId});
        this.setState({BazarName:bazarName});



        let jsonObject=
            {
                sql:"Select * from Client where 1=1 "+District+"  "+Upazila+" "+Ward_Union+"  ",

            };


                        RestClient.PostRequest(AppUrl.getClient,jsonObject).then(result=>{

                            document.getElementById("tableID").style.visibility="visible";
                            this.setState({Customer:result,loading:false})
                        });



    }


    saveOrder=()=>{

        let  MsgCategory=document.getElementById("ddlMsgCategory").value;


        let jsonObject=
            {
                Client_Id:this.state.Customer_Id,
                Msg:document.getElementById("ddlInfoMsg").value,
                MsgCategory:MsgCategory,
            };

        RestClient.PostRequest(AppUrl.saveClientInfo,jsonObject).then(result=>{

            cogoToast.success("Data Saved Successfully")
            this.handleClose();

             });



    }


    render() {



        if(this.state.Loading==true){
          return  <Loading/>
        }else{




        const columns=[
            {dataField: 'Id', text: 'ID',sort:true},
            {dataField: 'Name', text:'Name',sort:true},
            {dataField: 'F_Name', text: 'Father name',sort:true},
            {dataField: 'Mobile', text: 'Mobile',sort:true},
            {dataField: 'District', text: 'District',sort:true},


        ]
        const selectRow={
            mode:'radio',
            onSelect:(row,isSelect,rowIndex)=>{
                this.setState({Customer_Id:row['Id']});
                this.setState({Customer_Name:row['Name']});
                this.handleShow();
            }

        }

        return (

            <Fragment>
                <Container className="animated zoomIn">
                    <div className="purchaseDiv text-center">
                        <Nav.Link className="navItem "><NavLink to="/home"><Button variant="warning" className="float-end">Close</Button></NavLink></Nav.Link>

                        <h3>New Order  Entry Form</h3>
                        <hr/>

                        <Container>
                            <Row>
                                <Col lg={3} md={3} sm={12}>
                                    <Table>
                                        <tr>
                                            <td>
                                                <select className="float-start w-100 customfontsize" id="ddlDistrict"  onChange={this.getUpazila} >
                                                    <option value="0">-All District-</option>

                                                    {


                                                        this.state.Districts.map((ctr,index)=>{
                                                            return (
                                                                <option key={index} value={ctr.id}>{ctr.name}</option>
                                                            )
                                                        })

                                                    }


                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <select className="float-start w-100 customfontsize" id="ddlUpazila" onChange={this.getUnion}>
                                                    <option value="0">-All Upazila-</option>

                                                    {
                                                        this.state.new_upazila.map((ctr,index)=>{
                                                            return (
                                                                <option key={index} value={ctr.id}>{ctr.name}</option>
                                                            )
                                                        })
                                                    }


                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <select className="float-start w-100 customfontsize" id="ddlUnion"   >
                                                    <option>-All Union-</option>

                                                    {

                                                        this.state.new_union.map((ctr,index)=>{
                                                            return (
                                                                <option key={index} value={ctr.id}>{ctr.name}</option>
                                                            )
                                                        })

                                                    }


                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <select className="float-start w-100 customfontsize" id="ddlBazar"  >
                                                    <option>-All Category-</option>

                                                    {

                                                        this.state.Bazars.map((ctr,index)=>{
                                                            return (
                                                                <option key={index} value={ctr.id}>{ctr.name}</option>
                                                            )
                                                        })

                                                    }


                                                </select>
                                            </td>
                                        </tr>

                                    </Table>

                                </Col>
                                <Col lg={3}>

                                    <Row >
                                        <Col lg={6} md={6} sm={12}><Button onClick={this.getCustomerBy} variant="success" className="w-100 mt-4">Search</Button></Col>

                                    </Row>

                                </Col>

                            </Row>

                        </Container>

                    </div>

                    <div id="tableID" className="overflow-scroll tableDivBorder">
                        <div className="tableDiv">

                            <BootstrapTable
                                keyField='Id'
                                data={this.state.Customer }
                                columns={ columns }
                                selectRow={selectRow}
                            >
                            </BootstrapTable>
                        </div>
                    </div>

                </Container>


                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title >Client Name :-{this.state.Customer_Name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <select className="float-start w-100 customfontsize" id="ddlMsgCategory"  >
                            <option>General Info</option>
                            <option>Business Info</option>
                            <option>Private Info</option>
                            <option>Income Info</option>

                        </select>
                        <textarea className="infoTextArea" id="ddlInfoMsg"></textarea>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button variant="success" onClick={this.saveOrder}>
                            Save Order
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>





        );
    } }
}

export default OrderEntry;