import React, {Component, Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import OrderReport from "../components/Order/OrderReport";


class OrderReportPage extends Component {
    render() {
        return (
            <Fragment>
                <TopNavigation/>

                <OrderReport district={this.props.district} upazila={this.props.upazila} union={this.props.union}  bazar={this.props.bazar}/>


            </Fragment>
        );
    }
}

export default OrderReportPage;