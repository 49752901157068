class AppUrl {
    static BaseUrl='https://farco.akhi-enterprise.com';
    static getAccount=this.BaseUrl+'/getAccount';
    static getDistrict=this.BaseUrl+'/getDistrict';
    static getUpazila=this.BaseUrl+'/getUpazila';
    static getUnion=this.BaseUrl+'/getUnion';
    static getBazar=this.BaseUrl+'/getBazar';
    static insertBazar=this.BaseUrl+'/insertBazar';
    static insertCustomer=this.BaseUrl+'/insertCustomer';
    static getCustomer=this.BaseUrl+'/getCustomer';
    static getCustomerByDistrict=this.BaseUrl+'/getCustomerByDistrict';
    static insertOrder=this.BaseUrl+'/insertOrder';
    static customerUpdate=this.BaseUrl+'/customerUpdate';
    static getAllData=this.BaseUrl+'/allData';
    static getAllOrder=this.BaseUrl+'/getOrder';
    static getOrderBy=this.BaseUrl+'/getOrderBy';
    static getOrderBySql=this.BaseUrl+'/getOrderBySql';
    static getOrderSummery=this.BaseUrl+'/getOrderSummery';

    static getDistrict='https://siteapi.farcochemical.com'+'/getDistrict';
    static getUpazila='https://siteapi.farcochemical.com'+'/getUpazila';
    static getUnion='https://siteapi.farcochemical.com'+'/getUnion';
    static saveClient='https://siteapi.farcochemical.com'+'/saveClient';
    static getCategory='https://siteapi.farcochemical.com'+'/getCategory';
    static getClient='https://siteapi.farcochemical.com'+'/getClient';
    static saveClientInfo='https://siteapi.farcochemical.com'+'/saveClientInfo';



    static getBin='https://siteapi.farcochemical.com'+'/getBin';
    static getHscode='https://siteapi.farcochemical.com'+'/getHscode';

    //static getAccount='https://siteapi.farcochemical.com'+'/getAccount';

    //static sendSms='https://bulksmsbd.net/api/smsapi';


}
export default AppUrl

