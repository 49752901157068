import React, {Component, Fragment} from 'react';
import {Button, Col, Container, Nav, Row} from "react-bootstrap";
import { NavLink} from "react-router-dom";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";

class Login extends Component {


    constructor() {
        super();

        this.state={
            redirect:'',
            user:""


        }
    }


  getLogin=()=>{

      let UserName= document.getElementById("UserName").value;
      let Password = document.getElementById("Password").value;
      this.setState({user:UserName});

      document.cookie = UserName;

      let jsonObject=
          {
              UserName:UserName,
              Password:Password,

          };



      RestClient.PostRequest(AppUrl.getAccount,jsonObject).then(result=>{
          if(result==1){cogoToast.success("Login Succed");

              this.setState({redirect: '/home'});


          }else{
              cogoToast.error("Login Failed")
          }



      }).catch(error=>{
          cogoToast.error(error);
      });


  }
 checkLogin=()=>{

        if(this.state.redirect==""){
            cogoToast.error("Please Login First")
        }else{

        }
 }

    render() {
        return (
            <Fragment>


                    <div className="purchaseDiv text-center">
                        <Nav.Link className="navItem "><NavLink to="/"><Button variant="warning" className="float-end">Close</Button></NavLink></Nav.Link>


                        <h3>Login Form</h3>
                        <hr/>
                        <Container >


                            <Row>
                                <Col lg={4}>

                                </Col>

                                <Col lg={4}>
                                    <Row>
                                        <label className="customfontsize">User name</label>
                                        <input id="UserName" type="text" className="w-100 customfontsize ml-1"/>
                                    </Row>
                                    <Row>
                                        <label className="customfontsize">Password</label>
                                        <input id="Password" type="password" className="w-100  customfontsize"/>
                                    </Row>


                                    <Row >
                                        <Col lg={6} md={6} sm={12}>

                                            <Button onClick={this.getLogin} variant="success" className="w-100 mt-4">Login</Button>

                                        </Col>
                                        <Col  lg={6} md={6} sm={12}>
                                            <Nav.Link className="navItem "><NavLink to={this.state.redirect}>  <Button onClick={this.checkLogin}  variant="danger" className="w-100 mt-4" >Go to Home</Button> </NavLink></Nav.Link>

                                        </Col>
                                    </Row>

                                </Col>

                            </Row>
                        </Container>
                    </div>



            </Fragment>
        );
    }
}

export default Login;