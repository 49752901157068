import React, {Component, Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import OrderEntry from "../components/Order/OrderEntry";

class OrderPage extends Component {



    render() {


        return (
            <Fragment>
                <TopNavigation/>
                <OrderEntry district={this.props.district} upazila={this.props.upazila} union={this.props.union}  bazar={this.props.bazar}/>
            </Fragment>
        );
    }
}

export default OrderPage;