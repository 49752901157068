import React, {Component, Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import UnitProfile from "../components/BIN/UnitProfile";

class UnitProfilePage extends Component {
    render() {
        return (
            <Fragment>
                <TopNavigation/>
                <UnitProfile/>
            </Fragment>
        );
    }
}

export default UnitProfilePage;