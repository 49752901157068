import React, {Component, Fragment} from 'react';
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import {Button, Col, Container, Nav, Row, Table} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import cogoToast from "cogo-toast";
class BazarEntry extends Component {

    constructor(props) {
        super(props);

        this.state={


            Districts:[],
            Upazilas:[],
            Unions:[],
            Bazars:[],

            new_upazila:[],
            new_union:[],
            new_bazar:[],
            loading:true

        }
    }

    componentDidMount() {
        this.setState({Districts:this.props.district});
        this.setState({Upazilas:this.props.upazila});
        this.setState({Unions:this.props.union});
    }

    getUpazila=()=>{


        const e = document.getElementById("ddlDistrict");
        const value = e.options[e.selectedIndex].value;
        const dt=this.state.Upazilas.filter(x => x.district_id==value);
        this.setState({new_upazila: dt});
        this.setState({new_union: []});
        this.setState({new_bazar: []});
    }
    getUnion=()=>{
        const e = document.getElementById("ddlUpazila");
        const value = e.options[e.selectedIndex].value;
        const dt=this.state.Unions.filter(x => x.upazila_id==value);
        this.setState({new_union: dt});
        this.setState({new_bazar: []});


    }




    saveNewBazar(){
        let district = document.getElementById("ddlDistrict");
        let upazila = document.getElementById("ddlUpazila");

        const e = document.getElementById("ddlUnion");
        const unionName = e.options[e.selectedIndex].value;

        let bazar = document.getElementById("ddlBazar");



        let bazarName=bazar.value;




        let jsonObject=
            {
                union_id:unionName,
                bazar:bazarName,

            };

        if(district.selectedIndex==0){cogoToast.error('Please Select District');district.focus()}else{
            if(upazila.selectedIndex==0){cogoToast.error('Please Select Upazila');upazila.focus()}else{
                if(e.selectedIndex==0){cogoToast.error('Please Select Union');e.focus()}else{
                     if(bazarName==""){cogoToast.error('Bazar  Empty');bazar.focus()}else{

                                    RestClient.PostRequest(AppUrl.insertBazar,jsonObject).then(result=>{
                                        cogoToast.success(result);
                                    }).catch(error=>{
                                        cogoToast.error(error);
                                    });



                                }} } }


    }


    render() {
        return (
            <Fragment>
                <div className="purchaseDiv text-center">
                    <Nav.Link className="navItem "><NavLink to="/home"><Button variant="warning" className="float-end">Close</Button></NavLink></Nav.Link>

                    <h3>New Bazar  Entry Form</h3>
                    <hr/>

                    <Container>
                        <Row>
                            <Col lg={3} md={3} sm={12}>
                                <Table>
                                    <tr>
                                        <td>
                                            <select className="float-start w-100" id="ddlDistrict"  onChange={this.getUpazila} >
                                                <option value="0">-Select District-</option>

                                                {


                                                    this.state.Districts.map((ctr,index)=>{
                                                        return (
                                                            <option key={index} value={ctr.id}>{ctr.name}</option>
                                                        )
                                                    })

                                                }


                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <select className="float-start w-100" id="ddlUpazila" onChange={this.getUnion}>
                                                <option value="0">-Select Upazila-</option>

                                                {
                                                    this.state.new_upazila.map((ctr,index)=>{
                                                        return (
                                                            <option key={index} value={ctr.id}>{ctr.name}</option>
                                                        )
                                                    })
                                                }


                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <select className="float-start w-100" id="ddlUnion"  >
                                                <option>-Select Union-</option>

                                                {

                                                    this.state.new_union.map((ctr,index)=>{
                                                        return (
                                                            <option key={index} value={ctr.id}>{ctr.name}</option>
                                                        )
                                                    })

                                                }


                                            </select>
                                        </td>
                                    </tr>

                                </Table>

                            </Col>
                            <Col lg={3}>
                                <label className="customfontsize">Bazar name</label>
                                <input type="text" className="w-100 customfontsize" id="ddlBazar"/>
                                <Row >
                                    <Col lg={6} md={6} sm={12}><Button onClick={this.saveNewBazar} variant="success" className="w-100 mt-4">Save</Button></Col>
                                    <Col  lg={6} md={6} sm={12}><Button variant="danger" className=" w-100 mt-4">Reset</Button>
                                    </Col>
                                </Row>

                            </Col>


                        </Row>

                    </Container>

                </div>






            </Fragment>
        );
    }
}

export default BazarEntry;